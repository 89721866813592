import { prop, pipe } from '@/helpers'
import { Game, GameButton, GameFlag } from '../Game'

export const getId = prop(Game.id)
export const getName = prop(Game.name)
export const getUrlSlug = prop(Game.urlSlug)
export const getImage = prop(Game.image)
export const getVendorLogo = prop(Game.vendorLogo)
export const getRating = prop(Game.rating)

const getButtons = prop(Game.buttons) || {}
const getFlags = prop(Game.flags) || {}

export const hasDemo = pipe(getButtons, (buttons) => buttons?.[GameButton.demo])
export const hasPlay = pipe(getButtons, (buttons) => buttons?.[GameButton.play])
export const isNew = pipe(getFlags, (flags) => flags?.[GameFlag.new])
export const isHot = pipe(getFlags, (flags) => flags?.[GameFlag.hot])
